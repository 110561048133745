import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonSearchBar from '../../components/common/CommonSearchBar';
import '../../components/common/common.css';
import CommonTable from '../../components/common/CommonTable';
import { fetchEmiHistory } from '../../redux/features/emiHistorySlice';
import Loader from '../../constants/loader/Loader'
import { ABI, ABI2, ContractAddress, ContractAddress2 } from '../../Web3_interact/configuration';
import Web3 from "web3";
import { errorToast } from '../../constants/ShowToast';
import { generateEncryptedKeyBody } from '../../utils/encryption';
import { payEmi } from '../../redux/features/activeLoanDetailsSlice';
import { getEmi } from '../../redux/features/activeLoanDetailsSlice';

const Emi = () => {
  const id = {
    id: '64e5ef948e6223dcf3899f45'
  }
  const [loanData, setLoanData] = useState([]);
  const dispatch = useDispatch();
  const requestHeaders = ['S.No', 'Date', 'Txn Hash', 'Loan', 'From', 'USDT', ' Status', 'Pay Emi'];
  const headers = ['emiPaidDate', 'emiTransactionHash', 'loanType', 'userAddress', 'EMI', 'emiStatus', 'amount']
  const load = useSelector((state)=>state.emiHistory.loading);
  const [contract, setContract] = useState("");
  const [loanType, setLoanType] = useState("");
  const [contract2, setContract2] = useState("");


  // smart contract
  useEffect(() => {
    const init = async () => {
      if (window.ethereum !== "undefined") {
        try {
          await window.ethereum.request({ method: "eth_requestAccounts" });
          const web3 = new Web3(window.ethereum);
          const contractInstance = new web3.eth.Contract(ABI, ContractAddress);
          console.log(contractInstance, "contractInstace");
          setContract(contractInstance);
          const contractInstance2 = new web3.eth.Contract(ABI2, ContractAddress2);
          console.log(contractInstance, "contractInstace");
          setContract2(contractInstance2, 'contractInstance2');
        } catch (error) {
          console.error("Error:", error);
        }
      } else {
        console.error("Please install MetaMask or any other Web3 provider.");
      }
    };

    init();
  }, []);

  const handlePay = async (loanType) => {
    const data ={
      loanType: loanType.toUpperCase(),
    } 
    const body = generateEncryptedKeyBody(data)
    try {
      const promises = [dispatch(payEmi({ body ,id})), dispatch(getEmi(id))];

      const [payEmiResponse, getEmiResponse] = await Promise.all(promises);
      console.log("payEmiResponse:", payEmiResponse);

      const responseData = await getEmiResponse?.payload?.data;
      console.log("getEmiDataaaaaaaaaaaaaaaaaaaaaaaaaaaaa:", responseData);
      
    } catch (error) {
      console.error("Payment failed:", error.message);
    }
  };

  const PayEMi = async (emi_amount , typeOfLoan) => {
        try {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          const account = accounts[0];
          const transferApproval = await contract2.methods.approve(ContractAddress, emi_amount).send({from:account});
          console.log('=================transferApproval: ', transferApproval);
          if(transferApproval){
            const gas = await contract.methods.payEmi(typeOfLoan.toLowerCase()).estimateGas({ from: account})
          const transaction = await contract.methods
            .payEmi(typeOfLoan.toLowerCase())
            .send({ from: account ,gas: gas});
            if(transaction){
              handlePay(typeOfLoan);
            }
          }
          
        } catch (error) {
          errorToast(error?.data?.message)
          console.log(error, "payEMIERRRR");
        }
      };

  useEffect(() => {
    dispatch(fetchEmiHistory())
      .then(result => {
        if (result.payload.statusCode === 200) {
          setLoanData(result.payload.data[0].data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, [dispatch]);

  const handleSearch = (params) => {
    dispatch(fetchEmiHistory(params))
      .then(result => {
        console.log(result)
        if (result.payload.statusCode === 200) {
          setLoanData(result.payload.data[0].data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleReset = () => {
    dispatch(fetchEmiHistory())
    .then(result => {
      if (result.payload.statusCode === 200) {
        setLoanData(result.payload.data[0].data);
        
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  const mappedData = loanData.length > 0 ? loanData.map((loan) => {
    const formattedemiPaidDate = new Date(loan.emiPaidDate).toLocaleDateString('en-GB')
    return headers.reduce((acc, header) => {
      if (header === 'emiPaidDate') {
           acc[header] = acc[header] !== null ? '' : formattedemiPaidDate;      
      } 
     else {
        acc[header] = loan[header];
      }
      return acc;
    }, {});
  }) : [];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className='container'>
      <Loader isLoad={load}/>
      <div className='loan-wrp2'>
        <h2>EMI</h2>
        <hr />
        <CommonSearchBar onSearch={handleSearch} onReset={handleReset}/>
        <div className='loan-table'>
          {headers.length > 0 && <CommonTable headers={headers} data={mappedData} constHeader={requestHeaders} revert={PayEMi} setLoanType={setLoanType}/>}
        </div>
      </div>
    </div>
  );
};

export default Emi