
import { useEffect } from "react";
import "./App.css";
import Router from "./Routes";
import { ToastContainer, toast } from "react-toastify";
import Web3 from "web3";

function App() {
  console.log=() => {}

  // useEffect(() => {
  //   const init = async () => {
  //     if (window.ethereum !== "undefined") {
  //       try {
  //         window.ethereum.on("accountsChanged", function (sAccounts) {
  //           console.log("accountsChanged", sAccounts);
  //           setAccounts(sAccounts[0])
            
  //         })
  //         await window.ethereum.request({ method: "eth_requestAccounts" });
  //         const web3 = new Web3(window.ethereum);
  //         const contractInstance = new web3.eth.Contract(ABI, ContractAddress);
  //         console.log(contractInstance, "contractInstace");
  //         setContract(contractInstance);
  //         const accounts = await window.ethereum.request({
  //           method: "eth_requestAccounts",
  //         });
  //         setAccounts(accounts[0])
  //       } catch (error) {
  //         console.error("Error:", error);
  //       }
  //     } else {
  //       console.error("Please install MetaMask or any other Web3 provider.");
  //     }
  //   };

  //   init();
  // }, []);
  return (
    <div className="App">
      
      <Router />
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default App;
