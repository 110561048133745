import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_KEY} from '../../../constants/Urls';
import { errorToast } from '../../../constants/ShowToast';
import { STORAGE_KEYS, removeFromStorage } from '../../../constants/Storage';
import { ContractAddress2 } from '../../../Web3_interact/configuration';

export const EthApi = createAsyncThunk('EthApi/EthApi', async (accountAddress) => {

// const url =`${ETH_URL}?module=account&action=txlistinternal&address=${accountAddress}&startblock=0&endblock=99999999&page=1&offset=10&sort=asc&apikey=${API_KEY}`

const baseUrl = 'https://api-testnet.polygonscan.com/api';
const queryParams = new URLSearchParams({
  module: 'account',
  action: 'tokentx',
  address: accountAddress,
  contractaddress: ContractAddress2,
  page: 1,
  offset: 10,
  sort: 'asc',
  apikey: API_KEY,
});

const url = `${baseUrl}?${queryParams.toString()}`;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  });
  const apiData = await response.json();
  if (apiData?.statusCode === 401) {
    const errors = Object.values(apiData);
    if (errors?.length) {
      const error = errors[0];
      if (error) {          
        errorToast(error);
        removeFromStorage(STORAGE_KEYS.token);
        removeFromStorage(STORAGE_KEYS.userData);
        window?.location?.replace("/");
      }
    }
  }
  return apiData;
});


const transactionApiSlice = createSlice({
  name: 'EthApi',
  initialState: {
    status: false,
    error: null,
    user: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(EthApi.pending, (state) => {
        // console.log('no',state)
        state.status = true;
      })
      .addCase(EthApi.fulfilled, (state, action) => {
        // console.log('yes',action.payload)
        state.status = false;
        state.user = action.payload;
      })
      .addCase(EthApi.rejected, (state, action) => {
        // console.log('rej',action)
        state.status = false;
        state.user = action.payload
        state.error = action.error.message;
      });
  }
});

export default transactionApiSlice.reducer;
