import crypto from "crypto-js";

export function generateEncryptedKey() {
  // const algorithm = "aes-256-cbc";
  const iv = "9PLQTNVhJcWsbKTY";
  try {
    const key = crypto.lib.WordArray.random(32);
    const timestamp = Date.now();
    let dataPayload = { appkey: timestamp };
    let appkey = JSON.stringify(dataPayload);
    const encrypted = crypto.AES.encrypt(appkey, key, {
      iv: crypto.enc.Utf8.parse(iv),
      mode: crypto.mode.CBC,
    });
    const encryptedHex = encrypted.ciphertext.toString();
    const keyHash = key.toString();

    return {
      hash: keyHash,
      sek: encryptedHex,
      appkey,
    };
  } catch (error) {
    console.error("", error);
    return null;
  }
}

export function generateEncryptedKeyBody(body) {
  // const algorithm = "aes-256-cbc";
  const iv = "9PLQTNVhJcWsbKTY";
  try {
    const key = crypto.lib.WordArray.random(32);
    let dataPayload = body;
    let appkey = JSON.stringify(dataPayload);
    const encrypted = crypto.AES.encrypt(appkey, key, {
      iv: crypto.enc.Utf8.parse(iv),
      mode: crypto.mode.CBC,
    });
    const encryptedHex = encrypted.ciphertext.toString();
    const keyHash = key.toString();

    return {
      hash: keyHash,
      sek: encryptedHex,
    };
  } catch (error) {
    console.error("", error);
    return null;
  }
}
