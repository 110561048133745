import React, { useState } from "react";
import ImageUploadCard from "../../components/ImageDrop";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch ,useSelector} from "react-redux";
import { uploadPhoto } from "../../redux/features/uploadSlice";
import { profileData } from "../../redux/features/profileSlice";
import { successToast,errorToast } from "../../constants/ShowToast";
import { STORAGE_KEYS, getFromStorage, setToStorage } from "../../constants/Storage";
import { setData } from "../../redux/features/dataSlice";
import Loader from "../../constants/loader/Loader";
import { generateEncryptedKeyBody } from "../../utils/encryption";
import { Box, Button, Modal, Typography } from "@mui/material";


const CleraPhoto = ({setScreen,
  fullName,
  email,
  country,
  state,
  selectedDocument,
  fuRl, walletAddress}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
    borderRadius: 3
  };

  const [clrImg, setClrImg] = useState("");
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [error, setError] = useState("");
  const [imageFile, setImageFile] = useState(null);

  const load = useSelector(state => state.profile.loading);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleImageUpload = (imageFile) => {
    setIsImageUploaded(true);
    setError("");
    setImageFile(imageFile);
    dispatch(uploadPhoto(imageFile)).then((result) => {
      if (result.payload.statusCode === 200) {
        setClrImg(result.payload.data);
      }
    });
  };

  const handleNext = () => {
    if (!isImageUploaded) {
      setError("Please upload an image");
    } else {
      const body = {
        fullName: fullName,
        email: email,
        country: country,
        state: state,
        documentImage: fuRl,
        document:selectedDocument,
        image: clrImg,
        walletAddress : walletAddress,
      };
      const allData = generateEncryptedKeyBody(body)
     
      dispatch(profileData(allData)).then((result) => {
        const token = getFromStorage(STORAGE_KEYS?.token)
        if (result?.payload?.statusCode === 200) {
            const response = result?.payload?.data
            setToStorage(STORAGE_KEYS?.userData, JSON.stringify(response|| null))
            dispatch(
              setData({
                user: response || null,
                token :  token
              })
            )
          
          setOpen(true)
          setTimeout(() => {
            setOpen(false)
            navigate("/landingPage", { replace: true });
          }, 2000)
        }
        else {
        console.log(result?.payload,'500');

          errorToast(result?.payload?.message || ""); 
        }
      });
    }
  };

  return (
    <div className="Outer-div">
       <Loader isLoad={load} />
      <div className="cleraphoto-box">
        <h3 className="Simple-Text">Upload Your Clear Photo</h3>

        <div>
          <p className="para-text">Make sure your background should be a single color.</p>
        </div>

        <ImageUploadCard onImageUpload={handleImageUpload} setImageFile={setImageFile} />

        {error && <span className="error">{error}</span>}

        <button className="log-but next-flow-btn next-button" onClick={handleNext}>
          Next
        </button>
      </div>
      <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <figure className="check_img">
            <img src="/static/images/check.png" alt=""/>
          </figure>
          <Typography id="modal-modal-title" variant="h6" component="h2" className="cong">
            Congratulations!
          </Typography>
          <Typography className="cong_para" id="modal-modal-description" sx={{ mt: 2 }}>
            Your account created successfully.
          </Typography>
        </Box>
      </Modal>
    </div>
    </div>
  );
};

export default CleraPhoto;
