import React from "react";

import Mainpage from "../../features";

const Home = () => {
  return (
    <div>
        <Mainpage/>
    </div>
  );
};

export default Home;
